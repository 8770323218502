var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"modal fade",staticStyle:{"background-color":"#00000082"},attrs:{"id":"modal-form-reporte-internos","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(" Generar Reporte Solicitudes Internas ")]),_c('button',{ref:"closeModal1",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body p-0 pt-1"},[_vm._m(0),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Filtros"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.fecha_inicial),expression:"filtros.fecha_inicial"}],staticClass:"form-control form-control-sm",class:_vm.$v.filtros.fecha_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.filtros.fecha_inicial)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "fecha_inicial", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha Final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filtros.fecha_final),expression:"filtros.fecha_final"}],staticClass:"form-control form-control-sm",class:_vm.$v.filtros.fecha_final.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"date"},domProps:{"value":(_vm.filtros.fecha_final)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.filtros, "fecha_final", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":""}},[_vm._v("Empresa")]),_c('v-select',{staticClass:"p-0 col-md-12",class:[
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      ],attrs:{"placeholder":"Empresas","label":"razon_social","options":_vm.listasForms.empresas},on:{"input":function($event){return _vm.selectEmpresa()},"search":_vm.buscarEmpresas},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1)])])])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('div',[(
                _vm.$store.getters.can('tep.reportes.exportInternos') &&
                  !_vm.$v.filtros.$invalid
              )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.generarReporte()}}},[_vm._v(" Generar Reporte "),_c('i',{staticClass:"fas fa-file-download"})]):_vm._e()])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Filtros","data-toggle":"tab","href":"#Filtros"}},[_vm._v("Filtros ")])])])
}]

export { render, staticRenderFns }