<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reportes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Reportes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card-body p-5">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="input-group">
                    <h1 class="m-0 text-dark text-center">
                      Tablero de Reportes
                    </h1>
                    <div class="input-group-append"></div>
                  </div>
                  <div class="btn-group float"></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row clearfix">
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-facturacion"
                      >
                        <p class="float-center">
                          <span class="icon"
                            ><i
                              class="fa fa-list fa-3x"
                              style="color: green"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Informe Facturación</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-internos"
                        @click="$refs.ReporteInternos.limpiarModal()"
                        v-if="$store.getters.can('tep.reportes.exportInternos')"
                      >
                        <p class="float-center">
                          <span class="icon"
                            ><i
                              class="fas fa-clipboard-list fa-3x"
                              style="color: blue"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Informe Internos</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-3 column">
                    <div class="card card-widget widget-user">
                      <button
                        type="button"
                        class="btn text-white btn-default pt-4 pb-4"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-nacionales"
                        @click="$refs.ReporteNacionales.limpiarModal()"
                        v-if="
                          $store.getters.can('tep.reportes.exportNacionales')
                        "
                      >
                        <p class="float-center">
                          <span class="icon"
                            ><i
                              class="fas fa-clipboard-list fa-3x"
                              style="color: green"
                            ></i
                          ></span>
                        </p>
                        <div class="m-0 text-dark text-center">
                          <h4>Informe Nacionales</h4>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <ReporteFacturacion ref="ReporteFacturacion" />
    <ReporteInternos ref="ReporteInternos"></ReporteInternos>
    <ReporteNacionales ref="ReporteNacionales"></ReporteNacionales>
  </div>
</template>

<script>
import ReporteFacturacion from "./ReporteFacturacion";
import ReporteInternos from "./ReporteInternos";
import ReporteNacionales from "./ReporteNacionales";

export default {
  name: "ReporteIndex",
  data() {
    return {
      cargando: false,
      listasForm: {},
    };
  },
  components: {
    ReporteFacturacion,
    ReporteInternos,
    ReporteNacionales,
  },
  methods: {},
};
</script>
